// import Image from "next/image";
import images from "../../../assets";
import { renderImage } from "../../../helperfunction/imagehelper/renderImage";
import _Table from "./table";
import moment from "moment";

export default function Page1(props) {
  return (
    <div className="flex justify-center items-center flex-col pt-8">
      <div
        className="bg-no-repeat bg-center w-[735px]"
        // style={{
        //   backgroundImage: `url(${images.blurlogo2})`,
        //   backgroundSize: 500,
        // }}
      >
        <div className="  ">
          <div className="flex justify-between items-center ">
            <div className="absolute mb-12 ml-2">
              <img
                src={images.RFlogo}
                alt="Picture of the author"
                width={70}
                height={70}
              />
            </div>
            <div className="ml-20 ">
              <p className="text-[#2f3f90] font-extrabold text-3xl tracking-wider text-center">
                RESCUE FIRST AID TRAINING PVT. LTD.
              </p>
              <p className=" text-center tracking-wider text-xs font-bold ">
                <span className="text-[16px] font-extrabold">
                  {" "}
                  DR ATUL B BAND
                </span>
                ,MBBS, DPH, DCH, AFIH,
                <br />
                <span className="text-[11px]">
                  CERTIFYING SURGEON,(GOVT OF MAHARSHTRA),MARINE MEDICAL
                  EXAMINER (APPROVED BY GOVT INDIA)
                </span>
                <br />
                <span className="text-[#2f3f90] text-[11px]">
                  CERTIFYING SURGEON, FIRST AID TRAINING, HI TECH MEDICAL CHECK
                  UPS, OHC MANAGEMENT
                </span>
              </p>
            </div>
          </div>
          <div className="h-0.5 bg-slate-400 mt-1" />
          <p className="text-xs text-[#2f3f90] font-semibold text-center mt-2">
            Om Dwarkanath C.H.S., R. No. 9, Plot No. 11, Sector-19A, Nerul-East,
            Navi Mumbai-400 706.
            <br />
            Email :<span className="text-[#1a1aff]">
              {" "}
              mediglob13@gmail.com
            </span>{" "}
            Mob: +91 8956727718 / +91 9969789080
          </p>
        </div>
        <div className=" justify-between flex mt-2">
          {/* <div className=" absolute z-10 ml-[570px] mt-32 ">
            <img
              src={images.stamp}
              alt="Picture of the author"
              width={80}
              height={80}
            />
          </div> */}
          <div className="">
            <div className="flex items-center mt-2">
              <div className="w-[250px] flex justify-between items-center  ">
                Registration Number
                <p>:</p>
              </div>
              <p className="text-start ml-5 ">
                {props?.details?.registration_number.toUpperCase()}
              </p>
            </div>
            <div className="flex  items-center mt-2">
              <div className="w-[250px] flex justify-between items-center   ">
                Name ( in Block Letters)
                <p>:</p>
              </div>
              <p className="text-start ml-5">
                {" "}
                {props?.details?.employee_name.toUpperCase()}
              </p>
            </div>
            <div className="flex  items-center mt-2">
              <div className="w-[250px] flex justify-between items-center   ">
                Company
                <p>:</p>
              </div>
              <p className="text-start ml-5">
                {props?.details?.company_name.toUpperCase()}
              </p>
            </div>

            <div className="flex  items-center mt-2">
              <div className="w-[250px] flex justify-between items-center   ">
                Location
                <p>:</p>
              </div>
              <p className="text-start ml-5">
                {props?.details?.location.toUpperCase()}
              </p>
            </div>

            <div className="flex  items-center mt-2">
              <div className="w-[250px] flex justify-between items-center   ">
              Designation
                <p>:</p>
              </div>
              <p className="text-start ml-5">
                {props?.details?.designation.toUpperCase()}
              </p>
            </div>
            <div className="flex  items-center mt-2">
              <div className="w-[250px] flex justify-between items-center   ">
                Date of birth
                <p>:</p>
              </div>
              <p className="text-start ml-5">
                {" "}
                {moment(props?.details?.dob).format(" Do MMM Y")}
              </p>
            </div>
          
          </div>
          <div className="w-36 h-36 border-1 border-current flex justify-center items-center relative">
            <div className=" rounded-xl flex h-32 w-32 justify-center items-center ">
              <img
                className="rounded-xl   bg-center  h-[130px] w-[120px]"
                src={renderImage(props.details?.photo)}
                // src={props.details?.photo?.includes("drive") ? `https://drive.google.com/uc?export=view&id=${props.details?.photo}` : props.details?.photo}
                alt="Picture of the author"
              />
            
            </div>
            <img
              className="absolute -bottom-5 -left-8"
              src={images.stamp}
              alt="Picture"
              width={70}
              height={70}
            />
          </div>
        </div>
        <div className="border-t-transparent p-3">
          <p className="text-xl my-3 text-center font-Abril bg-yellow-200">
            Part 1 - To be filled in by Candidate before Medical Examination
          </p>

          <div className="flex justify-between items-center  ">
            <p className="text-[13px] mt-4">
              <span className="mr-2">1.</span> Are you currently being treated
              by a health professional for any illness or injury?
            </p>
            <div className="flex justify-between ">
              <div className="">
                <p className=" font-bold text-center mb-2">NO</p>
                <div className="w-14 h-10 flex justify-center items-center border-[1px] border-current">
                  <img
                    src={images.check}
                    alt="Picture of the author"
                    width={20}
                    height={22}
                  />
                </div>
              </div>
              <div className="ml-2">
                <p className=" font-bold text-center mb-2">YES</p>
                <div className="w-14 h-10 border-[1px] border-current"></div>
              </div>
            </div>
          </div>
          <_Table
            number={"2"}
            title={"Do you use any drugs or medications prescribed by Doctor?"}
          />
          <_Table
            number={"3"}
            title={
              "Do you use any drugs or medications not prescribed by Doctor?"
            }
          />
          <_Table number={"4"} title={"Do you have heart disease?"} />
          <_Table number={"5"} title={"Do you have high blood pressure?"} />
          <_Table
            number={"6"}
            title={"Do you have vertigo or fear of heights?"}
          />
          <_Table number={"7"} title={"Do you have sleep disorder?"} />
          <_Table number={"8"} title={"Do you have Epilepsy?"} />
          <_Table
            number={"9"}
            title={
              "Have you ever had any serious injury, illness, operation, or been in hospital for any reason?"
            }
          />
          <_Table
            number={"10"}
            title={"Have you ever been blackout/fainted?"}
          />
          <_Table number={"11"} title={"Do you drink alcohol?"} />
          <div className="flex justify-between px-5 mt-3 text-center ">
            <div className="">
              <div className="w-40 h-8 text-xs font-bold flex items-center justify-center">
                Date
              </div>
              <div className="w-40 mt-1 text-sm flex items-center justify-center">
                {" "}
                {moment(props.details.training_date).format(" Do MMM YY")}
              </div>
            </div>
            <div>
              <div className="w-40 h-8 text-xs font-bold flex items-center justify-center">
                Signature of Candidate
              </div>
              <div className="w-40 h-12 mt-1 text-sm flex items-center justify-center">
                <div className="h-36 flex justify-center items-center ">
                  {props.details.sign && (
                    <img
                      className="  bg-center  h-[40px] w-[150px] "
                      src={renderImage(props.details?.sign)}
                      // src={props.details?.sign?.includes("drive") ? `https://drive.google.com/uc?export=view&id=${props.details?.sign}` : props.details?.sign}
                      alt="Picture of the author"
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="w-48 h-8 text-xs font-bold flex items-center justify-center">
                Left Hand Thumb impression
              </div>
              <div className="w-48 h-12 flex justify-center items-center mt-1">
                {props.details.thumb && (
                  <img
                    className=" bg-center  h-[40px] w-[150px] "
                    src={renderImage(props.details?.thumb)}
                    // src={props.details?.thumb?.includes("drive") ? `https://drive.google.com/uc?export=view&id=${props.details?.thumb}` : props.details?.thumb}
                    alt="Picture of the author"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
