import React, { Component, useEffect, useState } from "react";
// import Image from "next/image";
import images from "../../assets";
import moment from "moment";
import { Opacity } from "@mui/icons-material";
import certificateService from "../../services/certificate.service";
import CircularProgress from "@mui/material/CircularProgress";
import { renderImage } from "../../helperfunction/imagehelper/renderImage";
import CertificateDataService from "../../services/certificate.service";
export default function Idcard(props) {
  const [api, setApi] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(props, "LLKKKK");
  useEffect(() => {
    function Apicall(id) {
      setLoading(true);
      CertificateDataService.gettranningdetails(props.match.params.id)

        .then((response) => {
          console.log(response, "trainning response");
          if (response.data.status) {
            setApi(response.data);
          }
        })
        .finally((e) => {
          setLoading(false);
        });
    }

    Apicall();
  }, [props.match.params.id]);

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  } else if (api === "") {
    return <div className="text-center">Data not found</div>;
  }

  console.log(api, "apicqallaing");
  return (
    <div className="flex flex-col justify-center items-center py-3">
      <div className="p-3 w-[700px] border-current bg-gradient-to-r from-cyan-100 to-blue-300 rounded-3xl">
        <div className="w-full flex items-start pt-2 border-b-4 border-blue-800 pb-2 justify-between px-2">
          <img
            className="ml-3 -mt-2"
            src={images.RFlogo}
            alt="Picture of the author"
            width={80}
            height={80}
          />
          <div className="flex flex-col  items-center mr-10">
            <div className="bg-blue-800 px-4 py-1.5">
              <p className="text-[#ffffff] font-semibold text-[22px] tracking-wider text-center">
                RESCUE FIRST AID TRAINING PVT. LTD.
              </p>
            </div>
            <p className="text-[#000] font-semibold text-sm tracking-wide text-center mt-2 px-4">
              Om Dwarkanath C.H.S, R.No.9, Plot No.11,Sector-19A, Nerul-East,
            </p>
            <p className="text-[#000] font-semibold text-sm tracking-wide text-center  px-4">
              Navi Mumbai-400 706
            </p>
          </div>
        </div>

        <div className="flex justify-start items-start text-[15px] px-2 mt-4 mb-2">
          <div className="flex justify-center">
            <div className=" absolute z-10 mt-24 mr-20">
              <img
                src={images.stamping}
                alt="Picture of the author"
                width={70}
                height={70}
              />
            </div>
            <div className=" border-[1px] border-slate-400 rounded-xl flex justify-center items-center">
              <img
                className="rounded-2xl bg-center h-[130px] w-[130px] "
                src={renderImage(api.data?.photo)}
                referrerPolicy="no-referrer"
                alt="Picture of the author"
              />
            </div>
          </div>
          <div className="ml-2">
            <div className=" ml-2">
              <div className="flex items-center">
                <div className="w-[200px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Name
                  <p>:</p>
                </div>
                <p className="text-start ml-5">
                  {api.data.employee_name.toUpperCase()}
                </p>
              </div>
              <div className="flex  items-center">
                <div className="w-[200px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Date Of Birth
                  <p>:</p>
                </div>
                <p className="text-start ml-5">
                  {" "}
                  {moment(api.data.dob).format(" Do MMM Y")}
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-[200px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Member ID
                  <p>:</p>
                </div>
                <p className="text-start ml-5">
                  {api.data.registration_number}
                </p>
              </div>
              <div className="flex  items-center">
                <div className="w-[200px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Medical Checkup Date
                  <p>:</p>
                </div>
                <p className="text-start ml-5">
                  {" "}
                  {moment(api.data.training_date).format(" Do MMM YY")}
                </p>
              </div>
              <div className="flex  items-center">
                <div className="w-[200px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Company Name
                  <p>:</p>
                </div>
                <p className="text-start ml-5">
                  {api.data.company_name.toUpperCase()}
                </p>
              </div>
              <div className="flex  items-center">
                <div className="w-[200px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Designation
                  <p>:</p>
                </div>
                <p className="text-start ml-5">
                  {" "}
                  {api.data.designation.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center relative px-2 mb-10 mt-2">
          {/* <div className="flex flex-col items-center mt-10">
            <p className="font-bold text-xl tracking-wide">Dr BS Parkar</p>{" "}
            <p className="font-bold text-sm">(Managing Director)</p>
          </div> */}

          <div className="text-[#2f3f90] font-semibold text-center -mb-5">
            <img
              className="rounded-2xl bg-center w-[140px] h-16 absolute right-10"
              src={images.sign}
              alt="Picture of the author"
            />
            <p className="text-base">DR. ATUL B. BAND</p>
            <p className="text-xs">MBBS, DPH, DCH, AFIH</p>
            <p className="text-xs">
              Indrustrial Health Physician and Certifying Surgeon <br /> Regn.
              MMC48470
            </p>
          </div>
        </div>
      </div>

      <div className="p-3 w-[700px] border-current bg-gradient-to-r from-cyan-200 to-blue-300 rounded-3xl mt-4 ">
        <div className="flex justify-between items-start text-[15px] px-2 mt-2 ">
          <div className="">
            <div className="">
              {/* <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Medical Examination Status
                  <p>:</p>
                </div>
                <p className="text-start"></p>
              </div> */}
              <div className="flex items-center ">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Blood Group
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">
                  {" "}
                  {api.data.blood_group.toUpperCase()}
                </p>
              </div>
              <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Vertigo Test
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Normal</p>
              </div>
              <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Vesion Test
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Normal</p>
              </div>
              <div className="flex items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Hearing Wishper Test
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Normal</p>
              </div>
              <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Gait Examination
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Normal</p>
              </div>
              <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Limb Mobility
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Normal</p>
              </div>
              <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Grip Strength Test
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Normal</p>
              </div>
              <div className="flex  items-center">
                <div className="w-[290px] flex justify-between items-center text-lg font-medium text-blue-800">
                  Physically Fit/Unfit For duties
                  <p>:</p>
                </div>
                <p className="text-start ml-5 font-medium">Fit</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-xl justify-center items-center w-[140px]">
            <img
              className="rounded-2xl bg-center h-[130px] w-[130px]"
              src={api.url}
              alt="Picture of the author"
              width={120}
              height={180}
            />
            <p className="text-xs font-medium text-center mt-2">
              PLEASE SCAN QR CODE FOR MEDICAL EXAMINATION DETAILS
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center ">
          <div className="flex flex-col  items-center">
            <div className=" pt-4">
              <p className="text-blue-800 font-semibold text-[22px] tracking-wider text-center">
                RESCUE FIRST AID TRAINING PVT. LTD.
              </p>
            </div>
            <p className="text-[#000] font-semibold text-sm tracking-wide text-center mt-1">
              FOR ANY QUERY
            </p>
            <p className="text-[#000] font-medium text-sm tracking-wide text-center mt-1">
              EMAIL ID-INFO@RESCUECARE.IN
            </p>
            <p className="text-[#000] font-medium text-sm tracking-wide text-center mt-1">
              CONTACT NUMBER-8433373205
            </p>
            <p className="text-[#000] font-semibold text-base tracking-wide text-center mt-1">
              Duplicacy and tempering of the id card is a criminal offence
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
