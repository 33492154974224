import { Urls } from "./const";
import axios, { isCancel, AxiosError } from "axios";
import ToastHandler from "../components/common/toasbar";
import useStorage from "../hooks/sessionstore";

export function makeRequest() {
  const { _errorToast } = ToastHandler();
  // const { getItem } = useStorage();

  // const token = getItem("token");

  async function _apirequest({ url, method, data }, cb) {
    // console.log(Urls.baseURL + url, method, data);
    return axios({
      method: method,
      url: Urls.baseURL + url,
      data: data,
      headers: {
        // Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status) {
          cb(res.data);
        } else {
          _errorToast(res.data?.message);
          cb(false);
        }
        // console.log(res);
      })
      .catch((error) => {
        // console.log(error.response);
        _errorToast(
          error?.response?.data.error_msg || error?.response?.data?.message
        );
      });
  }

  return {
    _apirequest,
  };
}
