import Page1 from "./newcert/page1";
import Page2 from "./newcert/page2";
import Page3 from "./newcert/page3";
import React, { Component, useEffect, useState } from "react";
// import Image from "next/image";
import images from "../../assets";
import moment from "moment";
import { Opacity } from "@mui/icons-material";
import certificateService from "../../services/certificate.service";
import CircularProgress from "@mui/material/CircularProgress";
import { renderImage } from "../../helperfunction/imagehelper/renderImage";
import CertificateDataService from "../../services/certificate.service";
import Page4 from "./newcert/page4";
export default function NewCert(props) {
  const [api, setApi] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(props, "certttt");
  useEffect(() => {
    function Apicall(id) {
      setLoading(true);
      CertificateDataService.getrescueVildetails(props.match.params.id)

        .then((response) => {
          console.log(response, "rescuevildetails");
          if (response.data.status) {
            setApi(response.data);
          }
        })
        .finally((e) => {
          setLoading(false);
        });
    }
    Apicall();
  }, [props.match.params.id]);

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  } else if (api === "") {
    return <div className="text-center">Data not found</div>;
  }
  return (
    <>
      <Page1 details={api.data} />
      <Page2 details={api.data} />
      <Page3 details={api.data} />
      <Page4 details={api.data} qr={api.url}/>
    </>
  );
}
