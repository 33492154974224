// import _Table from "./table";
import DoneIcon from "@mui/icons-material/Done";
import images from "../../../assets";
import moment from "moment";

export default function PAGE3(props) {
  const obj = {
    RegNo: props.details.registration_number,
    Name: props.details.employee_name,
    ValidTill: moment(props.details.valid_till_date).format(" Do MMM YY"),
    Company: props.details.company_name,
    Gender: props.details.gender,
    Fitness: "Fit",
    DOBAGE: moment(props.details.dob).format(" Do MMM Y"),
  };
  return (
    <div className="flex justify-center items-center flex-col  pt-10 ">
      <div
        className=" bg-center bg-no-repeat w-[750px]"
        style={{
          backgroundImage: `url(${images.blurlogo2})`,
          backgroundSize: 500,
        }}
      >
        <div className="">
          <div className="flex justify-between items-center">
            <div className="absolute mb-12 ml-2">
              <img
                src={images.RFlogo}
                alt="Picture of the author"
                width={70}
                height={70}
              />
            </div>
            <div className="ml-20 ">
              <p className="text-[#2f3f90] font-extrabold text-3xl tracking-wider text-center">
                RESCUE FIRST AID TRAINING PVT. LTD.
              </p>
              <p className=" text-center tracking-wider text-xs font-bold ">
                <span className="text-[16px] font-extrabold">
                  DR ATUL B BAND
                </span>
                ,MBBS, DPH, DCH, AFIH,
                <br />
                <span className="text-[11px]">
                  CERTIFYING SURGEON,(GOVT OF MAHARSHTRA),MARINE MEDICAL
                  EXAMINER (APPROVED BY GOVT INDIA)
                </span>
                <br />
                <span className="text-[#2f3f90] text-[11px]">
                  CERTIFYING SURGEON, FIRST AID TRAINING, HI TECH MEDICAL CHECK
                  UPS, OHC MANAGEMENT
                </span>
              </p>
            </div>
          </div>
          <div className="h-0.5 bg-slate-400 mt-1" />
          <p className="text-xs text-[#2f3f90] font-semibold text-center ">
            Om Dwarkanath C.H.S., R. No. 9, Plot No. 11, Sector-19A, Nerul-East,
            Navi Mumbai-400 706.
            <br />
            Email :<span className="text-[#1a1aff]">
              {" "}
              mediglob13@gmail.com
            </span>{" "}
            Mob: +91 8956727718 / +91 9969789080
          </p>
        </div>
        <div className="mt-2">
          <p className="text-xs font-semibold text-center ">
            Part 3- Certificate of Physical fitness
          </p>
          <div className="flex ml-2">
            <div className="">
              <table className="table-auto  text-xs font-normal ">
                <tbody className="">
                  <tr className="  h-8 ">
                    <td className="w-8 border-[1px]  border-current ">
                      {" "}
                      <span className="ml-2">1</span>{" "}
                    </td>
                    <td className="border-[1px] border-current  px-2">
                      Registration Number :{" "}
                    </td>
                    <td className="border-[1px] border-current px-2">
                      {obj.RegNo.toUpperCase()}
                    </td>
                    <td className="border-[1px] border-current  px-2">
                      Checkup Date{" "}
                    </td>
                  </tr>
                  <tr className="border  h-8 ">
                    <td className=" border-[1px]  border-current ">
                      {" "}
                      <span className="ml-2">2</span>{" "}
                    </td>
                    <td className="border-[1px] border-current  px-2">
                      Name :{" "}
                    </td>
                    <td className="border-[1px] border-current w-72 px-2">
                      {obj.Name.toUpperCase()}
                    </td>
                    <td className="border-[1px] border-current w-36 px-2">
                      Valid Till -{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-[1px] border-current h-8">
                      <span className="ml-2">3</span>
                    </td>
                    <td className="border-[1px] border-current px-2">
                      Company:
                    </td>
                    <td className="border-t border-r-0 border-b border-current px-2">
                      {obj.Company.toUpperCase()}
                    </td>
                    <td className="border-l-0 border-b border-r border-current px-2"></td>
                  </tr>
                  <tr>
                    <td className="border-[1px] h-8 border-current ">
                      <span className="ml-2">4</span>
                    </td>
                    <td className="border-[1px] border-current px-2">
                      Gender:
                    </td>
                    <td className="border-t border-r-0 border-b border-current px-2">
                      {obj.Gender.toUpperCase()}
                    </td>
                    <td className="border-l-0 border-b border-r border-current "></td>
                  </tr>
                  <tr>
                    <td className="border-[1px] h-8 border-current">
                      <span className="ml-2">5</span>
                    </td>
                    <td className="border-[1px] border-current px-2">
                      DOB/AGE :
                    </td>
                    <td className="border-t border-r-0 border-b border-current px-2">
                      {obj.DOBAGE}
                    </td>
                    <td className="border-l-0 border-b border-r border-current "></td>
                  </tr>
                  <tr>
                    <td className="border-[1px] h-8 border-current">
                      <span className="ml-2">6</span>
                    </td>
                    <td className="border-[1px] border-current px-2">
                      Fitness:
                    </td>
                    <td className="border-t border-r-0 border-b border-current">
                      <span className="ml-3">{obj.Fitness.toUpperCase()}</span>
                    </td>
                    <td className="border-l-0 border-b border-r border-current"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="border-[1px] border-current w-32 border-l-transparent border-l-0 ">
              <div className="h-8 px-2 border-b-[1px] border-current text-sm flex items-center">
                {moment(props.details.training_date).format(" Do MMM YY")}
              </div>
              <div className="h-8  px-2 border-b-[1px] border-current  text-sm flex items-center">
                {obj.ValidTill}
              </div>
              <div className="h-2/3 flex justify-center items-center">
                <img
                  className="rounded-2xl"
                  src={props.qr}
                  alt="Picture of the author"
                  width={120}
                  height={180}
                />
              </div>
            </div>
          </div>
          <div className="px-8 text-xs">
            <p className="mt-2">
              I hereby certify that Mr/Ms.{" "}
              <span className="text-sm font-semibold underline">
                {props.details.employee_name}
              </span>{" "}
              <br />
              has been examined by us, we cannot discover that he / she has got
              any disease, communicable or otherwise, constitutional or bodily
              deformity except __
            </p>

            <p className=" mt-5">Candidate is hereby declared,</p>

            <div className="flex justify-evenly mt-2">
              <div className="flex">
                FIT
                <div className="w-24 h-8 border-[1px] border-current flex justify-center items-center ml-5">
                  FIT
                </div>
              </div>

              <div className="flex">
                UNFIT
                <div className="w-24 h-8 border-[1px] border-current  ml-5"></div>
              </div>

              <div className="flex">
                Temporarily UNFIT
                <div className="w-24 h-8 border-[1px] border-current  ml-5"></div>
              </div>
            </div>

            <p className=" mt-5">for the post of</p>

            {props?.details?.postof ? (
              <div className=" mt-3 w-72  ">
                <div className="flex justify-between ">
                  1. Working on Height
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    {/* <DoneIcon style={{ width: 20 }} /> */}
                    {props?.details?.postof === 1 ? (
                      <DoneIcon style={{ width: 20 }} />
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-between mt-0.5">
                  2. Electrical work
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    {/* <DoneIcon style={{ width: 20 }} /> */}
                    {props?.details?.postof === 2 ? (
                      <DoneIcon style={{ width: 20 }} />
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-between mt-0.5">
                  3. Material lifting
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    {/* <DoneIcon style={{ width: 20 }} /> */}
                    {props?.details?.postof === 3 ? (
                      <DoneIcon style={{ width: 20 }} />
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-between mt-0.5">
                  4. Four wheeler Driving
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    {/* <DoneIcon style={{ width: 20 }} /> */}
                    {props?.details?.postof === 4 ? (
                      <DoneIcon style={{ width: 20 }} />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className=" mt-3 w-72  ">
                <div className="flex justify-between ">
                  1. Working on Height
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    <DoneIcon style={{ width: 20 }} />
                  </div>
                </div>
                <div className="flex justify-between mt-0.5">
                  2. Electrical work
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    <DoneIcon style={{ width: 20 }} />
                  </div>
                </div>
                <div className="flex justify-between mt-0.5">
                  3. Material lifting
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    <DoneIcon style={{ width: 20 }} />
                  </div>
                </div>
                <div className="flex justify-between mt-0.5">
                  4. Four wheeler Driving
                  <div className="w-20 h-6 border-[1px] border-current flex justify-center items-center ml-5">
                    <DoneIcon style={{ width: 20 }} />
                  </div>
                </div>
              </div>
            )}

            <p className="mt-5 leading-6">
              <span className="font-bold">Vertigo Test:</span> No Giddiness, No
              any other Abnormality seen during the test.
              <br />
              Doctor’s Full Name:{" "}
              <span className="font-bold">DR. ATUL B BAND</span>
            </p>
          </div>
          <div className="flex  mt-2 items-center ml-5">
            <img
              className="-mr-20 mb-3"
              src={images.stamping}
              alt="Picture of the author"
              width={65}
              height={75}
            />

            <div className="text-[#2f3f90] font-semibold text-center    ">
              <div className="ml-5">
                <img
                  className="rounded-2xl"
                  src={images.sign}
                  alt="Picture of the author"
                  width={120}
                  height={180}
                />
              </div>
              <p className="text-lg  ">DR. ATUL B. BAND</p>
              <p className="text-xs">MBBS, DPH, DCH, AFIH</p>
              <p className="text-[10px]">
                Indrustrial Health Physician and Certifying Surgeon <br /> Regn.
                MMC48470
              </p>
            </div>
          </div>
          <p className="text-xs ml-5">Seal & Signature:</p>
          <p className="text-xs mt-2  ml-5">Registration Number: MMC48470</p>
          <p className="text-xs mt-2 ml-5 mb-2">
            Date :{moment(props.details.training_date).format(" Do MMM YY")}
          </p>
        </div>
      </div>
    </div>
  );
}
