import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useProfile } from "../hooks/UserHooks";

const AuthProtected = (props) => {
  const { userProfile } = useProfile();
  const history = useHistory();

  /*
    redirect is un-auth access protected routes via url
    */
  useEffect(() => {
    if (!userProfile) {
      history.push("/");
    }
  }, [userProfile]);

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
