import images from "../../../assets";


export default function _Table(props){
    return(
        <div className="flex justify-between  items-center mt-0.5">
        <p className="text-[13px] ">
        <span className="mr-2">{props.number}.</span>{props.title}
        </p>
        <div className="flex justify-between ">
          <div className="">
          <div className="w-14 h-10 border-[1px] border-current flex justify-center items-center">
          <img
              src={images.check}
              alt="Picture of the author"
              width={20}
              height={22}
            />
          </div>
          </div>
          <div className=" ml-2">
            <div className="w-14 h-10 border-[1px] border-current"></div>
          </div>
        </div>
      </div>
    )
}