import images from "../../../assets";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
export default function Page3(props) {
  const Checedicon = (
    <DoneIcon
      style={{ width: 14, marginLeft: 15, stroke: "#000", strokeWidth: 2 }}
    />
  );
  return (
    <div className="flex justify-center pt-20">
      <div
        className="w-[780px] bg-no-repeat bg-center"
        // style={{
        //   backgroundImage: `url(${images.blurlogo2})`,
        //   backgroundSize: 500,
        // }}
      >
        <div className="flex justify-between items-center ">
          <div className="absolute mb-14 ml-2">
            <img
              src={images.RFlogo}
              alt="Picture of the author"
              width={68}
              height={70}
            />
          </div>
          <div className="ml-20 ">
            <p className="text-[#2f3f90] font-extrabold text-3xl tracking-wider text-center">
              RESCUE FIRST AID TRAINING PVT. LTD.
            </p>
            <p className=" text-center tracking-wider text-[11px] font-bold ">
              <span className="text-[16px] font-extrabold">
                {" "}
                DR ATUL B BAND
              </span>
              , MBBS, DPH, DCH, AFIH,
              <br />
              <span className="text-[11px]">
                CERTIFYING SURGEON,(GOVT OF MAHARSHTRA),MARINE MEDICAL EXAMINER
                (APPROVED BY GOVT INDIA)
              </span>
              <br />
              <span className="text-[#2f3f90] text-[11px]">
                CERTIFYING SURGEON, FIRST AID TRAINING, HI TECH MEDICAL CHECK
                UPS, OHC MANAGEMENT
              </span>
            </p>
          </div>
        </div>
        <div className="h-0.5 bg-slate-400" />
        <p className="text-[11px] text-[#2f3f90] font-semibold text-center ">
          Om Dwarkanath C.H.S., R. No. 9, Plot No. 11, Sector-19A, Nerul-East,
          Navi Mumbai-400 706.
          <br />
          Email :<span className="text-[#1a1aff]">
            {" "}
            mediglob13@gmail.com
          </span>{" "}
          Mob: +91 8956727718 / +91 9969789080
        </p>

        <div className="mt-10">
          <tr className="border-[1px] text-[13px]">
            <td className="w-72 border-[1px] border-current">
              <span className="ml-3 text-[14px]">6</span>
              <span className="ml-2">Hearing (audiometry) Test</span>
            </td>
            <td className="border-[1px] border-current w-32 px-2">Normal</td>
            <td className="border-[1px] border-current w-32 px-2">
              <span className="ml-5  font-extrabold">✓</span>
            </td>
            <td className="border-[1px] border-current w-32 px-2">Abnormal</td>
            <td className="border-[1px] border-current w-32 px-2">
              <span className="ml-5 w-9 font-extrabold">—</span>
            </td>
          </tr>
        </div>

        <div className="text-sm mt-3 flex justify-between">
          <div>
            <span className="font-extrabold">7 Vision Test</span>
          </div>
          {/* <td className="w-32 border-[1px]   border-current ">
              <span className="ml-2"></span>{" "}
            </td> */}
          <td className=" w-20"></td>
        </div>
        <div className="text-sm mt-1 flex justify-between">
          <div>a. Eye Site Test</div>
          <td className="w-32 border-[1px]   border-current ">
            <span className="ml-2">Normal</span>{" "}
          </td>
          <td className=" w-20"></td>
        </div>
        <div className="text-sm mt-1 flex justify-between">
          <div>b. color blindness</div>
          <td className="w-32 border-[1px]   border-current mr-3">
            <span className="ml-2">No</span>{" "}
          </td>
          <td className=" w-20"></td>
        </div>
        <div className="mt-2">
          <tr className=" text-[13px] flex items-center">
            <td className="w-72 ">
              <span className=" text-[14px]">8</span>
              <span className="ml-2 font-extrabold">Routine Urine Examination</span>
            </td>
            <td className=" w-32 px-2 flex items-center">
              ALBUMIN{" "}
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2"> Absent</span>{" "}
              </td>
            </td>

            <td className=" w-32 px-2 flex items-center">
              SUGAR{" "}
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2"> Absent</span>{" "}
              </td>
            </td>
          </tr>

          <p className="text-[13px] font-extrabold mt-1 ">
            9. Routine Blood examination
          </p>
          <div className="text-[13px] font-normal">a. HAEMOGRAM</div>
        </div>

        <div className="flex items-center text-[13px]">
          <div className="text">b.</div>
          <div className="">
            <span className=" w-32 px-2">
              Blood Group:{" "}
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2"> {props?.details?.blood_group}</span>{" "}
              </td>
            </span>
            <span className=" w-32 px-2">
              Rh factor:
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2">{props?.details?.rh_factor}</span>{" "}
              </td>
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="flex items-center text-[13px] mt-1">
          <div className="ml-10">
            <span className=" w-32 px-2">
              HB:
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2">{props?.details?.hb}</span>{" "}
              </td>
            </span>
            <span className=" w-32 px-2">
              TLC:
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2">{props?.details?.tlc}</span>{" "}
              </td>
            </span>
            <span className=" w-32 px-2">
              RBC:
              <td className="w-20 border-b-[1px]   border-current ">
                <span className="ml-2">{props?.details?.rbc}</span>{" "}
              </td>
            </span>
          </div>
        </div>
        <div className="text-[13px] font-normal mt-2">
          c. DLC – P L E M B:
          <span className="ml-1">{props?.details?.p}</span>
          <span className="ml-1">{props?.details?.l}</span>
          <span className="ml-1">{props?.details?.e}</span>
          <span className="ml-1">{props?.details?.m}</span>
          <span className="ml-1">{props?.details?.b}</span>
        </div>
        <div className="text-[13px] font-normal">
          d. Platelets Count:
          <span className="ml-2">{props?.details?.platelates_count}</span>
        </div>
        <div className="text-[13px] font-normal">
          e. Serum cholesterol:{" "}
          <span className="ml-1">{props?.details?.cholesterol}</span>
        </div>
        <div className="text-[13px] font-normal ml-2">
          S/Triglycerides: <span className="ml-1">{props?.details?.tgl}</span>
        </div>
        <div className="flex items-center">
          <div className="text-[13px] font-normal">
            f. HDL:<span className="ml-2">{props?.details?.hdl}</span>
          </div>
          <div className="text-[13px] font-normal ml-20">
            LDL:<span className="ml-2">{props?.details?.ldl}</span>
          </div>
        </div>
        <div className="text-[13px] font-normal">g. A1C: {props?.details?.hbac}</div>
        <div className="text-[13px] font-normal ml-2">(blood sugar level)</div>

        <div className="flex justify-start items-center mt-3">
          <img
            className="-mr-20 mb-3"
            src={images.stamp}
            alt="Picture"
            width={70}
            height={70}
          />

          <div className="text-[#2f3f90] font-semibold text-center">
            <img
              className="rounded-2xl   bg-center  h-[40px] w-[120px] ml-8 "
              src={images.sign}
              alt="Picture"
            />

            <p className="text-lg  ">DR. ATUL B. BAND</p>
            <p className="text-[11px]">MBBS, DPH, DCH, AFIH</p>
            <p className="text-[10px]">
              Indrustrial Health Physician and Certifying Surgeon <br /> Regn.
              MMC48470
            </p>
          </div>
        </div>
        <div className="h-80"/>
      </div>
    </div>
  );
}
