import { Component } from "react";
import { Switch, Route } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";
import UploadExl from "./components/certificate/upload.certificate";
import CertificateList from "./components/certificate/cetificatesLsit";
import CertDetails from "./components/certificate/details";
import Tranningupload from "./components/tranning/tranningupload";
import Tranningdetails from "./components/tranning/tranningdetails";
import Loginscreen from "./components/login/Loginscreen";
import { AuthProtected } from "./route/AuthProtected";
import TranningList from "./components/tranning/tranningList";

import Page1 from "./components/newcertificate/newcert/page1";
import Page2 from "./components/newcertificate/newcert/page2";
import Page3 from "./components/newcertificate/newcert/page4";
import Idcard from "./components/tranning/id";
import Image1 from "./components/medicalimage/medicalimage/image1";
import Image2 from "./components/medicalimage/medicalimage/image2";
import Image3 from "./components/medicalimage/medicalimage/image3";
import Image4 from "./components/medicalimage/medicalimage/image4";
import Medicalimages from "./components/medicalimage/medicalimage";
import NewCert from "./components/newcertificate/newcertificate";

class App extends Component {
  render() {
    // return (
    //   <>
    //     {/* <Page1 />
    //     <Page2 />
    //     <Page3 /> */}
    //     <Medicalimages />
    //   </>
    // );
    return (
      <div>
        <div className="container">
          <Switch>
            <Route exact path={["/", "/tutorials"]} component={Loginscreen} />
            {/* <Route exact path="/add" component={AddTutorial} /> */}
            {/* <Route exact path="/upload" component={UploadExl} /> */}
            {/* <Route exact path="/tutorials/:id" component={Tutorial} /> */}
            <Route exact path="/certdetails/:id" component={CertDetails} />
            {/* <Route exact path="/certdetails" component={CertDetails} /> */}
            <Route
              exact
              path="/tranningdetails/:id"
              component={Tranningdetails}
            />
            <Route exact path="/rescuevil/:id" component={NewCert} />
            <Route exact path="/vil/:id" component={Medicalimages} />
            {/* <Route exact path="/tranningDetails/:id" component={Listdetails} /> */}
            <AuthProtected>
              <Route exact path="/idcard/:id" component={Idcard} />
              
              <Route exact path="/tranning" component={TranningList} />
              <Route exact path={"/certificates"} component={CertificateList} />
              <Route exact path={"/certificate/upload"} component={UploadExl} />
              <Route exact path="/tranning/upload" component={Tranningupload} />
            </AuthProtected>
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
