const img = {
  RFlogo: "/assets/images/cert/RFlogo.png",
  sigimg: "/assets/images/sigimg.png",
  bg2: "/assets/images/cert/bg2.jpeg", //"/images/cert/bg2.jpeg",
  bg3: "/assets/images/cert/bg3.jpeg",
  sign: "/assets/images/cert/sign.png",
  stamping: "/assets/images/cert/stamping.png",
  img1: "/assets/images/cert/img1.png",
  img2: "/assets/images/cert/img2.png",
  img3: "/assets/images/cert/img3.png",
  img4: "/assets/images/cert/img4.png",

  stamp: "/assets/images/stamp.png",
  sigimg2: "/assets/images/sigimg2.png",
  borderimg: "/assets/images/borderimg.png",

  // tranning
  check: "/assets/images/tranning/check.png",
  sigimg: "/assets/images/tranning/sigimg.png",
  blurlogo: "/assets/images/cert/blurlogo.jpeg",
  blurlogo2: "/assets/images/cert/blurlogo2.jpeg",
  borderimg: "/assets/images/cert/borderimg.png",
  borderb: "/assets/images/cert/borderb.jpeg",
  qr: "/assets/images/cert/qr.png",
  
};

export default img;
