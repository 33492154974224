import { useState, useEffect } from "react";
import PAGE1 from "./tranningdetails/page1";
import PAGE2 from "./tranningdetails/page2";
import PAGE3 from "./tranningdetails/page3";
import CircularProgress from "@mui/material/CircularProgress";
import CertificateDataService from "../../services/certificate.service";
import images from "../../assets";
import moment from "moment";
import { Urls } from "../../http/const";

export default function Tranningdetails(props) {
  const [api, setApi] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(props, "LLKKKK");
  useEffect(() => {
    function Apicall() {
      setLoading(true);
      CertificateDataService.gettranningdetails(props.match.params.id)
        .then((response) => {
          console.log(response, "trainning response");
          if (response.data.status) {
            setApi(response.data);
          }
        })
        .finally((e) => {
          setLoading(false);
        });
    }

    Apicall();
  }, [props.match.params.id]);

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  } else if (api === "") {
    return <div className="text-center">Data not found</div>;
  }

  return (
    <div>
      <div className="flex flex-col items-center ">
        {api?.data?.firstpagephoto ? (
          <div className="pt-5">
            <div className="  pb-3">
              <div className="flex justify-between items-center ">
                <div className="absolute mb-12 ml-2">
                  <img
                    src={images.RFlogo}
                    alt="Picture of the author"
                    width={70}
                    height={70}
                  />
                </div>
                <div className="ml-16 ">
                  <p className="text-[#2f3f90] font-extrabold text-3xl tracking-wider text-center">
                    RESCUE FIRST AID TRAINING PVT. LTD.
                  </p>
                  <p className=" text-center tracking-wider text-xs font-bold ">
                    <span className="text-[16px] font-extrabold">
                      {" "}
                      DR ATUL B BAND
                    </span>
                    ,MBBS, DPH, DCH, AFIH,
                    <br />
                    <span className="text-[11px]">
                      CERTIFYING SURGEON,(GOVT OF MAHARSHTRA),MARINE MEDICAL
                      EXAMINER (APPROVED BY GOVT INDIA)
                    </span>
                    <br />
                    <span className="text-[#2f3f90] text-[11px]">
                      CERTIFYING SURGEON, FIRST AID TRAINING, HI TECH MEDICAL
                      CHECK UPS, OHC MANAGEMENT
                    </span>
                  </p>
                </div>
              </div>
              <div className="h-0.5 bg-slate-400 mt-1" />
              <p className="text-xs text-[#2f3f90] font-semibold text-center mt-2">
                Om Dwarkanath C.H.S., R. No. 9, Plot No. 11, Sector-19A,
                Nerul-East, Navi Mumbai-400 706.
                <br />
                Email :
                <span className="text-[#1a1aff]">
                  {" "}
                  mediglob13@gmail.com
                </span>{" "}
                Mob: +91 8956727718 / +91 9969789080
              </p>
            </div>
            <td className=" border-current px-2">Registration Number : </td>
            <td className=" border-current w-72 ">
              {api?.data?.registration_number.toUpperCase()}
            </td>
            <td className=" w-36 px-2">Checkup Date :</td>
            <td className="">
              {moment(api?.data?.training_date).format(" Do MMM YY")}
            </td>

            <div className="flex justify-center mt-1.5">
              {api?.data?.firstpagephoto && (
                <img
                  className="h-[750px] w-[750px]"
                  src={Urls.imgURL + api?.data?.firstpagephoto}
                  alt="Picture of the author"
                />
              )}
            </div>
            <div className="flex justify-end items-center">
              <img
                className="-mr-20 mb-3"
                src={images.stamping}
                alt="Picture of the author"
                width={70}
                height={70}
              />

              <div className="text-[#2f3f90] font-semibold text-center">
                <img
                  className="rounded-2xl   bg-center  h-[40px] w-[120px] ml-8 "
                  src={images.sign}
                  alt="Picture of the author"
                />

                <p className="text-lg  ">DR. ATUL B. BAND</p>
                <p className="text-[11px]">MBBS, DPH, DCH, AFIH</p>
                <p className="text-[10px]">
                  Indrustrial Health Physician and Certifying Surgeon <br />
                  Regn. MMC48470
                </p>
              </div>
            </div>
          </div>
        ) : (
          <PAGE1 details={api.data} />
        )}
      </div>

      <PAGE2 details={api.data} />
      <PAGE3 details={api.data} qr={api.url} />
      {/* <Idcard details={api.data} qr={api.url} /> */}
    </div>
  );
}
