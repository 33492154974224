import CertificateDataService from "../../services/certificate.service";
import { Link } from "react-router-dom";
import { Table, Spinner, Button } from "reactstrap";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../common/header";
import { useRef, useCallback, useState } from "react";
import Tables from "./helper/tranningtable";

import { useInfiniteQuery } from "react-query";
import { getTranningPage } from "../../http/axios";

const TranningList = () => {
  const [searchTitle, setSearchTitle] = useState("");
  const [, setloading] = useState(false);

  function onChangeSearchTitle(e) {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  }

  const {
    fetchNextPage, //function
    hasNextPage, // boolean
    isFetchingNextPage, // boolean
    data,
    status,
    error,
    isLoading,
    refetch,
  } = useInfiniteQuery(
    "/alltraning",
    ({ pageParam = 0 }) => getTranningPage(pageParam, searchTitle),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length ? allPages.length : undefined;
      },
    }
  );

  const intObserver = useRef();
  const lastItemRef = useCallback(
    (singleitem) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((items) => {
        if (items[0].isIntersecting && hasNextPage) {
          console.log("We are near the last data!");
          fetchNextPage();
        }
      });

      if (singleitem) intObserver.current.observe(singleitem);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  if (status === "error")
    return <p className="center">Error: {error.message}</p>;

  const onpressDelete = (id) => {
    setloading(true);
    CertificateDataService.delete_medical(id)
      .then((response: any) => {
        console.log(response, "response");
        refetch();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    refetch();
  };

  const handleClear = (e) => {
    e.preventDefault();
    setSearchTitle("");
  };

  const content = data?.pages.map((pg) => {
    return pg.map((item, i) => {
      if (pg.length === i + 1) {
        return (
          <Tables
            ref={lastItemRef}
            key={item.id}
            item={item}
            onpressDelete={onpressDelete}
          />
        );
      }
      return <Tables key={item.id} item={item} onpressDelete={onpressDelete} />;
    });
  });

  if (isLoading) {
    return (
      <div className="text-center">
        <Header />
        <CircularProgress size={40} title="Loading .... please wait" />
      </div>
    );
  }
  return (
    <>
      <Header />
      <div id="top" className="mb-12 flex  items-center mt-2">
        <input
          type="text"
          className="form-control mr-10"
          placeholder="Search by registration number"
          value={searchTitle}
          onChange={onChangeSearchTitle}
          handlekeypress={(target) => {
            if (target.key === "Enter") {
              refetch();
            }
          }}
          onKeyDown={(e) => {
            if (e?.key === "Enter") {
              e.preventDefault();
              refetch();
            }
          }}
        />
        <div className="input-group-append flex">
          <Button
            onClick={handleSubmit}
            className="btn btn-outline-secondary px-8 mr-3"
            color="primary"
            disabled={isLoading}
          >
            {isLoading && <Spinner size="sm">Loading...</Spinner>}
            <span>Search</span>
          </Button>

          <Button
            onClick={handleClear}
            className="btn btn-outline-secondary px-2 mr-3"
          >
            <span>Clear</span>
          </Button>
        </div>

        <Link to={"/tranning/upload"}>
          <Button color="success" size="lg">
            UPLOAD
          </Button>
        </Link>
      </div>
      <h1>Medical List</h1>
      <div className="">
        <Table striped className="flex text-center ">
          <thead className="">
            <tr>
              <th>Full Name</th>
              <th>Registration number</th>
              <th>Date</th>
              <th>Location</th>
              <th>Company name</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody className="">{content}</tbody>
        </Table>
      </div>
      {isFetchingNextPage && (
        <div className="flex text-center">
          {" "}
          <CircularProgress color="inherit" size={30} />
        </div>
      )}
      <p className="center absolute bottom-30, right-10">
        <a href="#top">Back to Top</a>
      </p>
    </>
  );
};
export default TranningList;
