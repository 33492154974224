import toast, { Toaster } from "react-hot-toast";

const Customethem = (t) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
              alt=""
            />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">Emilia Gates</p>
            <p className="mt-1 text-sm text-gray-500">
              Sure! 8:30pm works great!
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Close
        </button>
      </div>
    </div>
  );
};

function PopUp({ text, t, cb }) {
  return (
    <div
      style={{
        height: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ color: "red", fontWeight: "bold" }}>
        Are you sure you want to <text className="">{text}</text> ?{" "}
      </div>

      <div style={{ alignSelf: "flex-end" }}>
        <button
          style={{
            backgroundColor: "tomato",
            padding: 5,
            borderRadius: 10,
            marginRight: 10,
            paddingRight: 15,
            paddingLeft: 15,
          }}
          onClick={() => {
            toast.dismiss(t.id);
            cb();
          }}
        >
          <text style={{ color: "white" }}>OK</text>
        </button>

        <button
          style={{
            backgroundColor: "tomato",
            padding: 5,
            borderRadius: 10,
            marginLeft: 10,
            paddingRight: 15,
            paddingLeft: 15,
          }}
          onClick={() => toast.dismiss(t.id)}
        >
          <text style={{ color: "white" }}>Cancel</text>
        </button>
      </div>
    </div>
  );
}

function ToastHandler() {
  function _toast(text) {
    return toast(text);
  }

  function _successToast(text) {
    return toast.success(text, {});
  }

  function _errorToast(text) {
    return toast.error(text, {});
  }

  function _promise(text) {
    // return toast.promise(saveSettings(settings), {
    //   loading: "Saving...",
    //   success: <b>Settings saved!</b>,
    //   error: <b>Could not save.</b>,
    // });
  }

  function _withicon(text) {
    return toast(text, {
      icon: "👏",
    });
  }

  function _dark(text) {
    return toast(text, {
      icon: "👏",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  }

  function _theamtoast(text) {
    return toast.success(text, {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      iconTheme: {
        primary: "#713200",
        secondary: "#FFFAEE",
      },
    });
  }

  function _custom(params) {
    return toast.custom((t) => <Customethem t={t} />);
  }

  function _alert(text, cb) {
    return toast((t) => <PopUp text={text} cb={cb} t={t} />);
  }

  function _loading(params) {
    return toast.loading("Waiting...");
  }

  return {
    _toast,
    _successToast,
    _errorToast,
    _promise,
    _withicon,
    _dark,
    _theamtoast,
    _custom,
    _loading,
    _alert,
  };
}

export default ToastHandler;
