import { Link } from "react-router-dom";
import { Input, Button, Space } from "antd";
import { useHistory, useNavigate } from "react-router-dom";
import ToastHandler from "../common/toasbar";

export default function Header() {
  let history = useHistory();
  const { _successToast, _alert } = ToastHandler();

  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark p-2 mt-2 mb-8">
      {/* <Link to={"/"} className="navbar-brand">
        Home
      </Link> */}
      <div className="navbar-nav mr-auto ">
        {/* <li className="nav-item">
              <Link to={"/tutorials"} className="nav-link">
                Tutorials
              </Link>
            </li> 
            <li className="nav-item">
              <Link to={"/add"} className="nav-link">
                Add
              </Link>
            </li> */}
        <li className="navbar-brand ">
          <Link to={"/certificates"} className="nav-link">
            FA Cert.
          </Link>
        </li>
        <li className="navbar-brand">
          <Link to={"/tranning"} className="nav-link">
            Medical
          </Link>
        </li>
       
      
      </div>
      <Button
        className="text-white"
        onClick={() => {
          _alert("logout", () => {
            try {
              sessionStorage.removeItem("authUser");
              history.replace("/");
              _successToast("Logout Succeful");
            } catch (error) {
              console.log(error);
            }
          });
        }}
      >
        Logout
      </Button>
      {/* <div className="text-gray-400">Build version: 12.30.00</div> */}
    </nav>
  );
}
