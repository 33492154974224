import axios from "axios";
import { Urls } from "./const";

export const api = axios.create({
  baseURL: Urls.baseURL,
});

export const getTranningPage = async (pageParam = 1, filter, options = {}) => {
  const response = await api.get(
    `/alltraning?page=${pageParam}&registration_number=${filter}`,
    options
  );
  console.log(response, "response");
  return response.data?.data;
};

export const getCertificatePage = async (
  pageParam = 1,
  filter,
  options = {}
) => {
  const response = await api.get(
    `/allcertificate?page=${pageParam}&registration_number=${filter}`,
    options
  );
  console.log(response, "response");
  return response.data?.data;
};
