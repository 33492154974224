import React, { Component, useEffect, useState } from "react";

import Image1 from "./medicalimage/image1";
import Image2 from "./medicalimage/image2";
import Image3 from "./medicalimage/image3";
import Image4 from "./medicalimage/image4";
import images from "../../assets";
import moment from "moment";

import CircularProgress from "@mui/material/CircularProgress";

import CertificateDataService from "../../services/certificate.service";

export default function Medicalimages(props) {
  const [api, setApi] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function Apicall(id) {
      setLoading(true);
      CertificateDataService.getVildetails(props.match.params.id)

        .then((response) => {
          console.log(response, "image");
          if (response.data.status) {
            setApi(response.data);
          }
        })
        .finally((e) => {
          setLoading(false);
        });
    }
    Apicall();
  }, [props.match.params.id]);

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  } else if (api === "") {
    return <div className="text-center">Data not found</div>;
  }
  return (
    <>
      <Image1 details={api.data} />
      <Image2 details={api.data} />
      <Image3 details={api.data} />
      <Image4 details={api.data} qr={api.url} />
    </>
  );
}
