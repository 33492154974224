import { useState } from "react";
import { LockTwoTone } from "@ant-design/icons";
// import Button from "../_common/Button";
// import { useRouter } from "next/router";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
// import Link from "next/link";
import useStorage from "../../hooks/sessionstore";
import httprequest from "../../services/httprequest";
// import ToastHandler from "../common/toasbar";

function Loginscreen() {
  let history = useHistory();
  const { setItem } = useStorage();
  const { signIn } = httprequest();
  // const { _successToast } = ToastHandler();
  const [spinner, setspinner] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().email().required("Please enter your valid email"),
      password: yup
        .string()
        .required("Please enter your valid password")
        .min(6),
    }),
    onSubmit: (values) => {
      const reqData = {
        email: values?.email,
        password: values?.password,
      };
      submitSignin(reqData);
    },
  });

  async function submitSignin(reqData, values) {
       setspinner(true)
    await signIn(reqData, (res) => {
      if (res.status) {
        // console.log(res,'reddd')
        setspinner(false)
        setItem("token", res?.access_token);
        setItem("authUser", res?.data);
        history.push("/certificates");
        // navigate.replace({
        //   pathname: "/home",
        // });
      }
    });
    setspinner(false)
  }



  return (
    <div className="bg-[#ececec] flex justify-center items-center h-screen  ">
      <div className="w-96  p-5 bg-white my-12">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="text-[#999] text-sm mt-3">
            Email*
            <input
              prefix={<LockTwoTone />}
              style={{ height: 40 }}
              name="email"
              className="form-control"
              placeholder="Enter email"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && (
              <p className="text-red-500 text-sm mt-2">
                {validation.errors.email}
              </p>
            )}
          </div>

          <div className="text-[#999] text-sm mt-3">
            Password*
            <input
              prefix={<LockTwoTone />}
              style={{ height: 40 }}
              name="password"
              className="form-control"
              placeholder="Enter password"
              type="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password || ""}
              invalid={
                validation.touched.password && validation.errors.password
                  ? true
                  : false
              }
            />
            {validation.touched.password && (
              <p className="text-red-500 text-sm mt-2">
                {validation.errors.password}
              </p>
            )}
          </div>
          <br />

          <Button
            type="primary"
            ghost
            className="w-full mt-2 h-10 "
            onClick={() => {
              validation.handleSubmit();
            }}
            loading={spinner}
          >
            Login
          </Button>
        </form>
        <hr className="mt-10  border-1" />
        <div className="text-[#999]  relative top-3 text-center text-xs">
          © Rescuecare.in - 2022-2024 (06.28.03)
        </div>

      </div>

    </div>
  );
}
export default Loginscreen;
