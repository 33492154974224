import { Urls } from "../../http/const";

export function renderImage(val) {
    console.log(val,"vallllllll")
  if (val?.includes("amazonaws")) {
    return val
  } else if (val?.includes("/images/")) {
    return Urls.imgURL + val;
  } else {
    return `https://drive.google.com/uc?export=view&id=${val}`;
  }
}


// val?.includes("drive")