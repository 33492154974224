import React, { Component } from "react";
// import Image from "next/image";
import images from "../../assets";
import moment from "moment";
import { Opacity } from "@mui/icons-material";
import certificateService from "../../services/certificate.service";
import CircularProgress from "@mui/material/CircularProgress";
import { renderImage } from "../../helperfunction/imagehelper/renderImage";

export default class CertDetails extends Component {
  constructor(props) {
    super(props);
    // console.log(this?.props);
    this.state = {
      qrimage: "",
      itemId: this?.props?.location.state
        ? this?.props?.location.state?.id
        : this.props.match.params.id,
      // currentItem: this?.props?.location.state,
      currentItem: "", //this?.props?.location.state,
      loading: false,
    };
  }

  // this.props.match.params.id
  componentDidMount() {
    this.getoneCertificate(this?.state.itemId);
  }

  getoneCertificate(id) {
    this.setState({ loading: true });
    certificateService
      .get(id)
      .then((response) => {
        console.log("response", response);
        this.setState({
          currentItem: response.data?.data,
          qrimage: response?.data?.url,
          loading: false,
        });
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ loading: false });
  }

  render() {
    const { currentItem, loading } = this.state;
    if (loading)
      return (
        <div className="text-center">
          <CircularProgress />
        </div>
      );
    else if (!currentItem) {
      return <div className="text-center text-red-500">No data Found</div>;
    }

    return (
      <div className="flex justify-center items-center h-screen">
        <div
          className="p-2.5 bg-center bg-no-repeat flex justify-center items-center w-[780px] border-[1px] border-current "
          style={{
            backgroundImage: `url(${images.borderb})`,
          }}
        >
          <div
            className=" border-[1px] border-current  bg-center bg-no-repeat w-[760px] bg-white "
            style={{
              backgroundImage: `url(${images.blurlogo2})`,

              backgroundSize: 450,
            }}
          >
            <div className="">
              <div className="h-3  ">
                <p className="text-center  mx-48 bg-white ">
                  Certificate No. : {currentItem?.registration_number}
                </p>
              </div>
            </div>
            <div className="w-full flex pt-3">
              <div className=" w-24 ">
                <img
                  className="ml-8"
                  src={images.RFlogo}
                  alt="Picture of the author"
                  width={80}
                  height={80}
                />
              </div>
              <div className="flex flex-col justify-between items-center w-4/5">
                <div>
                  <p className="text-[#374aab] font-extrabold text-[28px] tracking-wider">
                    RESCUE FIRST AID TRAINING PVT. LTD.
                  </p>
                  <p className=" text-center tracking-wider text-[18px]">
                    FIRST AID EMERGENCY AND PREPAREDNESS TRAINING
                  </p>
                </div>
                <p className="pb-2 text-[38px] font-extrabold text-[#374aab] text-center  font-Certificate ">
                  Certificate of Participation
                </p>
              </div>
            </div>

            <div className="flex justify-between items-center text-[15px] px-12 ">
              <div className=" flex justify-center pl-5">
                <div className=" absolute z-10 mt-20 mr-28">
                  <img
                    src={images.stamp}
                    alt="Picture of the author"
                    width={80}
                    height={80}
                  />
                </div>
                <div className=" border-[1px] border-slate-400 rounded-xl flex h-32 w-32 justify-center items-center">
                  {/* <img src={currentItem?.photo} width={120} height={140} /> */}
                  <img
                    className="rounded-2xl   bg-center  h-[120px] w-[120px] "
                    src={renderImage(currentItem?.photo)}
                    // src={currentItem?.photo?.includes("drive") ? `https://drive.google.com/uc?export=view&id=${currentItem?.photo}` : currentItem?.photo}
                    referrerPolicy="no-referrer"
                    alt="Picture of the author"
                  />
                </div>
              </div>
              <div>
                <div className="w-[350px] ml-2">
                  <div className="flex justify-between ">
                    <p>Mr./Ms.</p>

                    <p className="text-start  w-64">
                      : {currentItem?.full_name?.toUpperCase()}
                    </p>
                  </div>
                  <div className="flex justify-between mt-0.5">
                    <p>Designation </p>

                    <p className="  w-64">
                      : {currentItem?.designation?.toUpperCase()}
                    </p>
                  </div>
                  <div className="flex justify-start  mt-0.5">
                    <p>Employee ID</p>

                    <p className="  ml-2 ">: {currentItem?.employee_id}</p>
                  </div>
                  <div className="flex justify-between  mt-0.5">
                    <p>Held at</p>

                    <p className="  w-64">
                      : {currentItem?.location?.toUpperCase()}
                    </p>
                  </div>

                  <div className="flex justify-between  mt-0.5">
                    <p>Company </p>
                    <p className="  w-64">
                      : {currentItem?.company_name?.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-current rounded-2xl h-[100px] w-[90px] flex justify-center items-center mr-10 ">
                <img
                  className="rounded-2xl"
                  src={this.state.qrimage}
                  alt="Picture of the author"
                  width={120}
                  height={180}
                />
              </div>
            </div>

            <div className="text-center text-[15px] mt-[25px]  ">
              This is to certify that the above named participant has
              successfully completed the <br />
              <span className="italic font-semibold">
                “FIRST AID EMERGENCY AND PREPAREDNESS TRAINING"
              </span>
              Programme <br />
              with Grade :{" "}
              {currentItem?.grade ? (
                <span className="font-bold">{currentItem?.grade}</span>
              ) : (
                <span className="font-bold">Excellent</span>
              )}
            </div>

            <div className="flex justify-between items-center px-10">
              <div className="text-[15px] ">
                DATE :{" "}
                <span className="font-semibold">
                  {moment(currentItem?.training_date).format("Do MMM YY")}
                </span>
              </div>
              {/*  */}

              <div className="">
                <div className="">
                  <img
                    className="rounded-2x "
                    src={images.sigimg}
                    alt="Picture of the author"
                    width={130}
                    height={40}
                    color="#374aab"
                  />
                </div>

                <p className="italic text-sm"> Chief Learning Officer</p>
                <p className="font-semibold">DR. ATUL B. BAND.</p>
                <p className="text-[10px]"> MBBS,DPH,DCH,AFIH</p>
              </div>
            </div>
            <div className="text-[10px] text-center">
              Email : info@rescuecare.in{" "}
            </div>
            <div className="h-3 flex justify-between absolute">
              <div className=" mx-[200px] bg-blue-400 text-xs  text-white px-2 flex justify-between items-center">
                <span>
                  Excellent <span> {">"} </span>80%
                </span>{" "}
                <span className="ml-2">Very Good {">"}70% </span>{" "}
                <span className="ml-2">Good {">"}60% </span>{" "}
                <span className="ml-2">Fair {">"}50%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
