import { Component, ChangeEvent } from "react";
import CertificateDataService from "../../services/certificate.service";
import { UploadFileData } from "../../types/certificate.type";
import { Table, Button } from "reactstrap";
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  history: any;
};

type State = UploadFileData & {
  err: String;
  files: "";
  iserror: String;
  message: String;
  loading:boolean
};

class UploadExl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      files: "",
      err: "",
      iserror: "",
      message: "",
      loading:false
    };
  }

  onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("helllllo", e);
    this.setState({
      files: e.target.files,
    });
  };

  uploadFile = () => {
    // console.log("helooo", this.state.message);

    this.setState({ message: "this is message" });
    // console.log(this.state.message);
    if (!this.state.files) {
      return this.setState({
        err: "Please select an excel file!",
        iserror: "alert-warning",
        loading:false
      });
    } else {
      let data = new FormData();
      this.setState({loading:true})
      data.append("file", this.state.files[0]);
      CertificateDataService.uplaod(data)
        .then((response: any) => {
          // console.log(">>>>>", response);
          if (response.status === 200) {
            this.setState({
              err: response?.data?.message,
              iserror: "alert-success",
              loading:false
            });

            this.props.history.goBack();
          } else {
            this.setState({
              err: response?.statusText,
              iserror: "alert-danger",
              loading:false
            });
          }
        })
        .catch((e) => {
          this.setState({
            err: e.response?.data?.message
              ? e.response?.data?.message
              : e.response?.statusText,
            iserror: "alert-danger",
            loading:false
          });
        });
    }
  };

  render() {
    const { err } = this.state;
    return (
      <div className="">
        <div>
          <div className="form-group">
            <label className="mb-3" htmlFor="title">
              Upload Cetificate File (only .xlsx file)
            </label>
            <div>{this.state.message}</div>
            <input
              className="form-control form-control-lg"
              type="file"
              onChange={this.onChangeTitle}
            />
          </div>
          <div className="w-full   mt-5 flex justify-center items-center">
            <button
              onClick={this.uploadFile}
              className="btn btn-success w-72 py-3"
            >
              {this.state.loading?  (<CircularProgress /> ) :("UPLOAD")}
            </button>
          </div>
          <div style={{ marginTop: 50 }} />
          {err && (
            <div className={`alert ${this.state.iserror}`} role="alert">
              <div className="alert-link">{err}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UploadExl;
