// import Image from "next/image";
import images from "../../../assets";
import { renderImage } from "../../../helperfunction/imagehelper/renderImage";
import _Table from "./image4";
import moment from "moment";
import { Urls } from "../../../http/const";

export default function Image1(props) {
  return (
    <div className="flex justify-center items-center flex-col pt-2  ">
      <div className="pt-2 w-[735px]">
        <div className="  pb-3">
          <div className="flex justify-between items-center ">
            <div className="absolute mb-12 ml-2">
              <img
                src={images.RFlogo}
                alt="Picture of the author"
                width={70}
                height={70}
              />
            </div>
            <div className="ml-20">
              <p className="text-[#2f3f90] font-extrabold text-3xl tracking-wider text-center">
                RESCUE FIRST AID TRAINING PVT. LTD.
              </p>
              <p className=" text-center tracking-wider text-xs font-bold ">
                <span className="text-[16px] font-extrabold">
                  {" "}
                  DR ATUL B BAND
                </span>
                ,MBBS, DPH, DCH, AFIH,
                <br />
                <span className="text-[11px]">
                  CERTIFYING SURGEON,(GOVT OF MAHARSHTRA),MARINE MEDICAL
                  EXAMINER (APPROVED BY GOVT INDIA)
                </span>
                <br />
                <span className="text-[#2f3f90] text-[11px]">
                  CERTIFYING SURGEON, FIRST AID TRAINING, HI TECH MEDICAL CHECK
                  UPS, OHC MANAGEMENT
                </span>
              </p>
            </div>
          </div>
          <div className="h-0.5 bg-slate-400 mt-1" />
          <p className="text-xs text-[#2f3f90] font-semibold text-center mt-2">
            Om Dwarkanath C.H.S., R. No. 9, Plot No. 11, Sector-19A, Nerul-East,
            Navi Mumbai-400 706.
            <br />
            Email :<span className="text-[#1a1aff]">
              {" "}
              mediglob13@gmail.com
            </span>{" "}
            Mob: +91 8956727718 / +91 9969789080
          </p>
        </div>
        {/* <td className=" border-current px-2">Registration Number : </td>
        <td className=" border-current w-72 ">
          {api?.data?.registration_number.toUpperCase()}
        </td>
        <td className=" w-36 px-2">Checkup Date :</td>
        <td className="">
          {moment(api?.data?.training_date).format(" Do MMM YY")}
        </td> */}

        <div className="flex justify-center mt-1.5 relative ">
          {/* {api?.data?.firstpagephoto && ( */}
          <img
            className="h-[850px] w-[650px]"
            src={Urls.imgURL + props?.details?.vil_image4}
            alt="Picture of the author"
          />
          <div className=" border-1 border-current absolute bottom-36 right-16">
            <img
              className="rounded-2xl "
              src={props.qr}
              alt="Picture of the author"
              width={120}
              height={180}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
