// import Image from "next/image";
import images from "../../../assets";
import { renderImage } from "../../../helperfunction/imagehelper/renderImage";
import _Table from "./image4";
import moment from "moment";
import { Urls } from "../../../http/const";

export default function Image1(props) {
  return (
    <div className="flex justify-center items-center flex-col ">
      <div className="pt-2 w-[735px]">
        {/* <td className=" border-current px-2">Registration Number : </td>
        <td className=" border-current w-72 ">
          {api?.data?.registration_number.toUpperCase()}
        </td>
        <td className=" w-36 px-2">Checkup Date :</td>
        <td className="">
          {moment(api?.data?.training_date).format(" Do MMM YY")}
        </td> */}

        <div className="flex justify-center mt-1.5 ">
          {/* {api?.data?.firstpagephoto && ( */}
          <img
            className="h-[1000px] w-[680px]"
            src={Urls.imgURL + props?.details?.vil_image3}
            alt="Picture of the author"
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
}
