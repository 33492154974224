import { makeRequest } from "../http/makerequest";

export default function httprequest() {
  const { _apirequest } = makeRequest();
  const signIn = (data, cb) =>
    _apirequest({ url: "/auth/login", method: "post", data }, cb);

  const changePassword = (data, cb) =>
    _apirequest({ url: "/auth/changepassword", method: "post", data }, cb);



  return {
    signIn,
    changePassword,
 
  };
}
