import http from "../http-common";
import { UploadFileData, ResponseFile } from "../types/certificate.type";

class CertificateDataService {
  uplaod(file: any) {
    return http.post<Array<UploadFileData>>("/certificateupload", file);
  }

  tranuplaod(file: any) {
    return http.post<Array<UploadFileData>>("/tranningupload", file);
  }
  gettranningdetails(id: string) {
    return http.get<Array<ResponseFile>>(`/tranningdetails/${id}`);
  }

  getrescueVildetails(id: string) {
    return http.get<Array<ResponseFile>>(`/rescuevildetails/${id}`);
  }
  

  getVildetails(id: string) {
    return http.get<Array<ResponseFile>>(`/vildetails/${id}`);
  }



  getalltraininglist() {
    return http.get<Array<ResponseFile>>("/alltraning");
  }

  getAll() {
    return http.get<Array<ResponseFile>>("/");
  }

  allCertificates() {
    return http.get<Array<ResponseFile>>("/allcertificate");
  }

  get(id: string) {
    return http.get<Array<ResponseFile>>(`/certificate/${id}`);
  }
  


  delete_certificate(id: string) {
    return http.delete<Array<ResponseFile>>(`/certificate/${id}`);
  }
  delete_medical(id: string) {
    return http.delete<Array<ResponseFile>>(`/tranning/${id}`);
  }

  // get(id: string) {
  //   return http.get<ITutorialData>(`/tutorials/${id}`);
  // }

  // create(data: ITutorialData) {
  //   return http.post<ITutorialData>("/tutorials", data);
  // }

  // update(data: ITutorialData, id: any) {
  //   return http.put<any>(`/tutorials/${id}`, data);
  // }

  // delete(id: any) {
  //   return http.delete<any>(`/tutorials/${id}`);
  // }

  // deleteAll() {
  //   return http.delete<any>(`/tutorials`);
  // }

  // findByTitle(title: string) {
  //   return http.get<Array<ITutorialData>>(`/tutorials?title=${title}`);
  // }
}

export default new CertificateDataService();
