// import React from 'react'
// const Post = React.forwardRef(({ post }, ref) => {
//     const postBody = (
//         <>
//             <h2>{post.title}</h2>
//             <p>{post.body}</p>
//             <p>Post ID: {post.id}</p>
//         </>
//     )

//     const content = ref
//         ? <article ref={ref}>{postBody}</article>
//         : <article>{postBody}</article>

//     return content
// })
// export default Post

import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ToastHandler from "../../common/toasbar";
import { Link } from "react-router-dom";
import moment from "moment";

const Tables = React.forwardRef(({ item, onpressDelete }, ref) => {
  const itemBody = (
    <>
      <td>{item?.employee_name?.toUpperCase()}</td>
      <td>{item?.registration_number?.toUpperCase()}</td>
      <td>{moment(item?.training_date?.toUpperCase()).format(" Do MMM YY")}</td>
      <td>{item?.location?.toUpperCase()}</td>
      <td>{item?.company_name?.toUpperCase()}</td>
      <td>
        <Link
          to={{
            pathname: "/tranningdetails/" + item.id,
            state: item,
          }}
          className="badge badge-warning"
        >
          <button className="py-1 px-3  bg-blue-400 text-white rounded-md text-xs">
            Download
          </button>
        </Link>
        <Link
          to={{
            pathname: "/idcard/" + item.id,
            state: item,
          }}
        >
          <button className="py-1 px-3  bg-blue-400 text-white rounded-md text-xs font-bold">
            Print ID
          </button>
        </Link>

        {item.vil_image1 && (
          <Link
            to={{
              pathname: "/vil/" + item.id,  
              state: item,
            }}
          >
            <button className="py-1 px-3  bg-blue-400 text-white rounded-md text-xs font-bold ml-2">
              VIL
            </button>
          </Link>
        )}

        <Link
          to={{
            pathname: "/rescuevil/" + item.id,
            state: item,
          }}
        >
          <button className="py-1 px-3  bg-blue-400 text-white rounded-md text-xs font-bold ml-2">
            Rescue VIL
          </button>
        </Link>
        <DeleteForeverIcon
          style={{ color: "#668cff", marginLeft: 10 }}
          onClick={() => {
            ToastHandler()._alert("delete " + item?.employee_name, () => {
              onpressDelete(item.id);
            });
          }}
        />
      </td>
    </>
  );
  const content = ref ? <tr ref={ref}>{itemBody}</tr> : <tr>{itemBody}</tr>;
  return content;
});

export default Tables;
