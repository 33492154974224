import images from "../../../assets";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
export default function Page2(props) {
  const Checedicon = (
    <DoneIcon
      style={{ width: 14, marginLeft: 15, stroke: "#000", strokeWidth: 2 }}
    />
  );
  return (
    <div className="flex justify-center pt-5">
      <div
        className="w-[750px] bg-no-repeat bg-center"
        // style={{
        //   backgroundImage: `url(${images.blurlogo2})`,
        //   backgroundSize: 500,
        // }}
      >
        <div className="flex justify-between items-center ">
          <div className="absolute mb-14 ml-2">
            <img
              src={images.RFlogo}
              alt="Picture of the author"
              width={68}
              height={70}
            />
          </div>
          <div className="ml-20 ">
            <p className="text-[#2f3f90] font-extrabold text-3xl tracking-wider text-center">
              RESCUE FIRST AID TRAINING PVT. LTD.
            </p>
            <p className=" text-center tracking-wider text-[11px] font-bold ">
              <span className="text-[16px] font-extrabold">
                {" "}
                DR ATUL B BAND
              </span>
              , MBBS, DPH, DCH, AFIH,
              <br />
              <span className="text-[11px]">
                CERTIFYING SURGEON,(GOVT OF MAHARSHTRA),MARINE MEDICAL EXAMINER
                (APPROVED BY GOVT INDIA)
              </span>
              <br />
              <span className="text-[#2f3f90] text-[11px]">
                CERTIFYING SURGEON, FIRST AID TRAINING, HI TECH MEDICAL CHECK
                UPS, OHC MANAGEMENT
              </span>
            </p>
          </div>
        </div>
        <div className="h-0.5 bg-slate-400" />
        <p className="text-[11px] text-[#2f3f90] font-semibold text-center ">
          Om Dwarkanath C.H.S., R. No. 9, Plot No. 11, Sector-19A, Nerul-East,
          Navi Mumbai-400 706.
          <br />
          Email :<span className="text-[#1a1aff]">
            {" "}
            mediglob13@gmail.com
          </span>{" "}
          Mob: +91 8956727718 / +91 9969789080
        </p>

        <div className=" p-1">
          <p className="text-[13px] font-bold  bg-yellow-200 pl-2 my-2">
            Part 2 - Clinical Examination/ Lab test
          </p>
          <div className="text-sm font-medium mt-3 flex justify-between">
            Cardiovascular system{" "}
            <td className="w-44 ">
              Date:
              <span className="ml-1">
                {" "}
                {moment(props.details.training_date).format(" Do MMM YY")}
              </span>{" "}
            </td>
          </div>
          <p className="text-sm font-medium mt-1">1. BMI</p>
          <table className="table-auto mt-1 text-[11px] font-medium  ">
            <tbody className="">
              <tr className="border-[1px]">
                <td className="w-44 border-[1px]   border-current ">
                  <span className="ml-2">BMI</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-24 px-2">
                  {props.details.bmi}
                </td>
                <td className="border-[1px]  border-current w-44 px-2">
                  Height
                </td>
                <td className="border-[1px]  border-current w-24 px-2">
                  {props.details.height} cm.
                </td>
                <td className="border-[1px]  border-current w-44 px-2">
                  Weight
                </td>
                <td className="border-[1px]  border-current w-24 px-2">
                  {props.details.weight} Kg.
                </td>
              </tr>
            </tbody>
          </table>

          <p className="text-sm font-medium mt-1">
            2. Blood pressure-(repeat if Necessary)
          </p>
          <table className="table-auto mt-1 text-[11px] font-medium  ">
            <tbody className="">
              <tr className="">
                <td className="w-72 border-[1px]   border-current  ">
                  {" "}
                  <span className="ml-3 text-[11px]">a.</span>
                  <span className="ml-2">Systolic</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  {props.details.bp_systolic}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">mmHg</td>
                <td className="border-[1px]  border-current w-32 px-2"></td>
                <td className="border-[1px] border-current w-32 px-2">mmHg</td>
              </tr>

              <tr className="border-[1px]    ">
                <td className="w-72 border-[1px] border-current ">
                  <span className="ml-3 text-[11px]">b.</span>
                  <span className="ml-2">Diastolic</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  {props.details.bp_diastolic}
                </td>
                <td className="border-[1px] border-current w-32 px-2">mmHg</td>
                <td className="border-[1px]  border-current w-32 px-2"></td>
                <td className="border-[1px]  border-current w-32 px-2">mmHg</td>
              </tr>

              <tr className="border-[1px] ">
                <td className="w-72 border-[1px]  border-current ">
                  <span className="ml-3 text-[11px]">c.</span>
                  <span className="ml-2">Pulse rate</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Regular
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Irregular
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]    ">
                <td className="w-72 border-[1px]   border-current ">
                  <span className="ml-3 text-[11px]">d.</span>
                  <span className="ml-2">Heart sounds</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px] ">
                <td className="w-72 border-[1px]   border-current ">
                  <span className="ml-3 text-[11px]">e.</span>
                  <span className="ml-2">Peripheral pulses</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px]   border-current ">
                  <span className="ml-3 text-[11px]">
                    <text className="font-extrabold">3</text> a.
                  </span>
                  <span className="ml-2">Respiratory Test</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px] ">
                <td className="w-72 border-[1px]   border-current ">
                  <span className="ml-3 text-[11px]">b.</span>
                  <span className="ml-2">Chest/Lungs</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px] ">
                <td className="w-72 border-[1px]   border-current ">
                  <span className="ml-3 text-[11px]">c.</span>
                  <span className="ml-2">Asthma</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px]  border-current ">
                  <span className="ml-3 text-[11px] font-bold">
                    4 <text className="font-medium">a.</text>
                  </span>
                  <span className="ml-2">Abdomen </span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px]  border-current ">
                  <span className="ml-3 text-[11px] font-normal"> b</span>
                  <span className="ml-2">Liver Function test</span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px]  border-current ">
                  <span className="ml-3 text-[11px] font-normal"> b</span>
                  <span className="ml-2">
                    Kidney (Renal) Function Test
                  </span>{" "}
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-[11px] mt-1 font-bold">
            5. Neurological / Locomotor
          </p>
          <table className="table-auto mt-1 text-[11px] font-medium">
            <tbody className="">
              <tr className="border-[1px] ">
                <td className=" border-[1px]   border-current ">
                  <span className="ml-3 text-[11px]">a.</span>
                  <span className="ml-2">Cervical sprine rotation</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>

              <tr className="border-[1px] ">
                <td className="w-72 border-[1px]  border-current">
                  <span className="ml-3 text-[11px]">b.</span>
                  <span className="ml-2"> Back movement </span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px]  border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="">
                <td className="w-72 border-[1px] border-current">
                  <span className="ml-3 text-[11px]">c.</span>
                  <span className="ml-2">Upper limbs</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px] border-current">
                  <span className="ml-16">Appearance</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px] border-current">
                  <span className="ml-16">Join movements</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px] border-current">
                <td className="w-72 border-[1px]  border-current">
                  <span className="ml-3 text-[11px]">d.</span>
                  <span className="ml-2">Lower limbs</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px]  border-current ">
                  <span className="ml-16">Appearance</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px]  border-current ">
                  <span className="ml-16">Join movements</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px] border-current">
                  <span className="ml-3 text-[11px]">e.</span>
                  <span className="ml-2">Reflexes</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
              <tr className="border-[1px]">
                <td className="w-72 border-[1px] border-current">
                  <span className="ml-3 text-[11px]">f.</span>
                  <span className="ml-2">Romberg's sign</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Normal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5  font-extrabold">✓</span>
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  Abnormal
                </td>
                <td className="border-[1px] border-current w-32 px-2">
                  <span className="ml-5 w-9 font-extrabold">—</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-[11px]">
            A pass requires the ability to maintain balance while standing with
            shoes off, <br />
            feet together side by side, eyes closed and arms by sides, for 30
            seconds
          </p>
          <td className="w-72 flex items-center mt-2">
            <span className="ml-3 text-[14px]">g.</span>
            <span className="ml-2 text-sm">
              Headache/Migrane/Epilepsy/Vertigo:<span className="ml-2 font-semibold">{props?.details?.headache}</span>
            </span>
          </td>
          <td className="w-72 flex items-center mt-2">
            <span className="ml-3 text-[14px]">h.</span>
            <span className="ml-2 text-sm">
              Anxiety/Nervousness:
              <span className="ml-2 font-semibold">{props?.details?.anxiety}</span>
            </span>
          </td>
          <td className="w-72 flex items-center mt-2">
            <span className="ml-3 text-[14px]">i.</span>
            <span className="ml-2 text-sm">
              Grip Strength Test:<span className="ml-2 font-semibold">{props?.details?.gripstrengthtest}</span>
            </span>
          </td>
         

          <div className="flex justify-end items-center mt-10">
            <img
              className="-mr-20 mb-3"
              src={images.stamp}
              alt="Picture"
              width={70}
              height={70}
            />

            <div className="text-[#2f3f90] font-semibold text-center">
              <img
                className="rounded-2xl   bg-center  h-[40px] w-[120px] ml-8 "
                src={images.sign}
                alt="Picture"
              />

              <p className="text-lg  ">DR. ATUL B. BAND</p>
              <p className="text-[11px]">MBBS, DPH, DCH, AFIH</p>
              <p className="text-[10px]">
                Indrustrial Health Physician and Certifying Surgeon <br /> Regn.
                MMC48470
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
