import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ToastHandler from "../../common/toasbar";
import { Link } from "react-router-dom";
import moment from "moment";

const Tables = React.forwardRef(({ item, onpressDelete }, ref) => {
  const itemBody = (
    <>
      <td>{item?.full_name?.toUpperCase()}</td>
      <td>{item?.employee_id?.toUpperCase()}</td>
      <td>{item?.registration_number?.toUpperCase()}</td>
      <td>{moment(item?.training_date?.toUpperCase()).format(" Do MMM YY")}</td>
      <td>{item?.company_name?.toUpperCase()}</td>
      <td>
        <Link
          to={{
            pathname: "/certdetails/" + item.id,
            state: item,
          }}
          className="badge badge-warning"
        >
          <button className="py-1 px-3  bg-blue-400 text-white rounded-md text-xs">
            Download
          </button>
          
        </Link>
        <DeleteForeverIcon
          key={item?.id}
          style={{ color: "#668cff", marginLeft: 10 }}
          onClick={() => {
            ToastHandler()._alert("delete "
              + item?.full_name, () => {
                onpressDelete(item?.id);
              });
          }}
        />
      </td>
    </>
  );
  const content = ref ? <tr ref={ref}>{itemBody}</tr> : <tr>{itemBody}</tr>;
  return content;
});

export default Tables;
